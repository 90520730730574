import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import isEqual from "lodash/isEqual";
import axios from "axios";

import {
  SContainer,
  SInnerContainer,
  SModuleCard,
  SModuleCardDescription,
  SModuleCardHeader,
  SModuleCardLogotype,
  SModuleCardSubTitle,
  SModuleCardTitle,
  SModuleContent,
  SModulesHeader,
  SModulesList,
} from "../MainScreen/styled-components";
import { IProps } from "./types";
import { business, guards } from "@/types";
import { Dropdown } from "@/components";
import { collectNodeValues } from "@/utils";
import chunk from "lodash/chunk";
import { GroupedMarkdowns } from "./GroupedMarkdowns";
import {
  SActionsContainer,
  SDownloadButtonsContainer,
} from "./styled-components";
import { DefaultButton } from "@fluentui/react";
import { unstable_batchedUpdates } from "react-dom";

const ExportScreenImpl: FC<IProps> = (props) => {
  const { modulesList } = props;

  const navigationCaches = useRef(new Map());

  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedModule, setSelectedModule] =
    useState<business.IModuleDto | null>(null);
  const [moduleVersion, setModuleVersion] =
    useState<business.IModuleChildrenDto | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [markdownsLoaded, setMarkdownsLoaded] = useState(false);
  const [selectedMarkdownsChunk, setSelectedMarkdownsChunk] = useState<
    number | null
  >(null);
  const [moduleNavigation, setModuleNavigation] = useState<
    business.INavigationElementDto[]
  >([]);

  const loadMarkdowns = useCallback(
    async (
      mdNavigation: business.INavigationElementDto[],
      moduleVersion: business.IModuleChildrenDto
    ) => {
      try {
        const mapResultData = (
          innerNavigation: business.INavigationElementDto[],
          result: any[]
        ): any[] => {
          try {
            return innerNavigation.map((nav, index) => {
              if (nav.children)
                return {
                  ...nav,
                  children: mapResultData(
                    nav.children,
                    result[index].status === "fulfilled"
                      ? (result[index] as PromiseFulfilledResult<any>).value
                      : null
                  ),
                };
              return {
                ...nav,
                mdData:
                  result[index].status === "fulfilled"
                    ? (result[index] as PromiseFulfilledResult<any>).value
                    : null,
              };
            });
          } catch (e) {
            console.log(e);
          }
          return [];
        };

        const loadInnerMarkdowns = async (
          innerNavigation: business.INavigationElementDto[]
        ): Promise<any> => {
          try {
            return Promise.allSettled(
              innerNavigation.map((nav) => {
                if (nav.children) return loadInnerMarkdowns(nav.children);

                return nav.mdPath
                  ? axios.get(nav.mdPath)
                  : Promise.resolve(null);
              })
            );
          } catch (e) {
            console.log(e);
          }
          return null;
        };

        const markdownPromises = mdNavigation.map(async (nav) => {
          if (nav.children) return loadInnerMarkdowns(nav.children);
          return nav.mdPath ? axios.get(nav.mdPath) : null;
        });

        Promise.allSettled(markdownPromises).then((result) => {
          const resultData = mdNavigation.map((nav, index) => {
            if (nav.children)
              return {
                ...nav,
                children: result?.[index]
                  ? mapResultData(
                      nav.children,
                      result[index].status === "fulfilled"
                        ? (result[index] as PromiseFulfilledResult<any>).value
                        : null
                    )
                  : [],
              };
            return {
              ...nav,
              mdData:
                result[index].status === "fulfilled"
                  ? (result[index] as PromiseFulfilledResult<any>).value
                  : null,
            };
          });

          navigationCaches.current.set(
            moduleVersion.navigationPath,
            resultData
          );

          unstable_batchedUpdates(() => {
            setModuleNavigation(resultData);
            setIsUpdating(false);
            setMarkdownsLoaded(true);
          });
        });
      } catch (e) {
        console.log(e);
      }
    },
    []
  );

  const getNavigationData = useCallback(
    async (moduleVersion: business.IModuleChildrenDto) => {
      try {
        if (moduleVersion && moduleVersion.navigationPath) {
          setMarkdownsLoaded(false);

          setIsUpdating(true);

          const { data: newNavigation } = await axios.get(
            moduleVersion.navigationPath
          );

          await loadMarkdowns(newNavigation, moduleVersion);
        } else {
          setMarkdownsLoaded(true);
        }
      } catch (e) {
        console.log(e);
      }
      return [];
    },
    [loadMarkdowns]
  );

  const updateNavigationData = useCallback(async () => {
    try {
      const newModule = selectedModule
        ? modulesList.find((md) => md.name === selectedModule.name)
        : null;

      if (newModule && selectedValue) {
        const newModuleVersion =
          (newModule.children || []).find(
            (mdVersion: business.IModuleChildrenDto) =>
              selectedValue!.startsWith(mdVersion.basePath)
          ) || (newModule.children || [])[0];

        if (!isEqual(newModuleVersion, moduleVersion)) {
          setModuleVersion(newModuleVersion);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [moduleVersion, modulesList, selectedModule, selectedValue]);

  const moduleSelectOptions = useMemo(() => {
    return selectedModule?.children && selectedModule.children.length > 0
      ? selectedModule.children.map((md) => ({
          key: md.basePath,
          text: md.name,
        }))
      : [];
  }, [selectedModule]);

  const markdownsList = useMemo<any[]>(() => {
    if (moduleNavigation) {
      const result: any[] = [];

      const newNavigation = moduleNavigation?.[2]?.children?.[5] || {
        children: [],
      };

      newNavigation.children.forEach((mdNavigation) => {
        result.push(mdNavigation.mdData);
      });

      return result;
    }

    return [];
  }, [moduleNavigation]);

  const chunkedMarkdownsList = useMemo(
    () => chunk(markdownsList, 50),
    [markdownsList]
  );

  useEffect(() => {
    updateNavigationData();
  }, [updateNavigationData]);

  useEffect(() => {
    if (moduleVersion) {
      getNavigationData(moduleVersion);
    }
  }, [getNavigationData, moduleVersion]);

  return (
    <>
      <SContainer>
        <SInnerContainer>
          <SModulesHeader>Выберите модуль для выгрузки</SModulesHeader>
          <SModulesList>
            {modulesList.map((module) => (
              <li key={module.name}>
                <SModuleCard
                  to={
                    module.children && module.children.length > 0
                      ? module.children[module.children.length - 1].basePath
                      : module.basePath
                  }
                  onClick={(ev: React.MouseEvent<HTMLAnchorElement>) => {
                    ev.preventDefault();

                    setModuleNavigation([]);
                    setModuleVersion(null);
                    setSelectedMarkdownsChunk(null);
                    setSelectedModule(module);
                  }}
                  $borderColor={module.accentColor}
                >
                  <SModuleCardHeader>
                    <SModuleCardLogotype
                      src={`/docs/_media${module.basePath}/logotype.svg`}
                      alt={`${module.name} logotype`}
                    />
                    <SModuleCardTitle>{module.name}</SModuleCardTitle>
                  </SModuleCardHeader>
                  <SModuleContent>
                    <SModuleCardSubTitle>{module.name}</SModuleCardSubTitle>
                    <SModuleCardDescription>
                      {module.description}
                    </SModuleCardDescription>
                  </SModuleContent>
                </SModuleCard>
              </li>
            ))}
          </SModulesList>
          {selectedModule?.children && selectedModule.children.length > 0 ? (
            <SActionsContainer>
              <Dropdown
                isDisabled={isUpdating}
                options={moduleSelectOptions}
                value={selectedValue}
                onChange={(newValue: any) => {
                  setSelectedMarkdownsChunk(null);
                  setSelectedValue(newValue || null);
                }}
              />
              <SDownloadButtonsContainer>
                {chunkedMarkdownsList.map((_cml, cmlIdx) => (
                  <DefaultButton
                    disabled={!markdownsLoaded}
                    key={cmlIdx}
                    onClick={() => {
                      setSelectedMarkdownsChunk(cmlIdx);
                    }}
                  >{`Скачать [${cmlIdx + 1}]`}</DefaultButton>
                ))}
              </SDownloadButtonsContainer>
            </SActionsContainer>
          ) : null}
        </SInnerContainer>
      </SContainer>
      {!isUpdating &&
      markdownsLoaded &&
      moduleNavigation &&
      markdownsList.length > 0 &&
      guards.isNumber(selectedMarkdownsChunk) ? (
        <GroupedMarkdowns
          markdowns={chunkedMarkdownsList[selectedMarkdownsChunk]}
        />
      ) : null}
    </>
  );
};

export const ExportScreen = memo(ExportScreenImpl);
