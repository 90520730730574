import styled from "styled-components";

export const SDownloadButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SActionsContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 300px max-content;
`;
