import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BREAKPOINTS, COLORS } from '@/constants';

export const SContainer = styled.div`
  padding: 30px 40px 30px 0;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-area: version;

  & > * + * {
    margin-top: 42px;
  }

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    grid-area: version;
    position: initial;
    padding: 1rem 1rem 0 1rem;
  }
`;

export const SPageContextHeader = styled.span`
  padding: 14px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: ${COLORS.darkDoctrix};
  border-bottom: 1px solid #e0e0e0;
`;

export const SContextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  left: 0;

  & > * + * {
    margin-top: 20px;
  }

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    display: none;
  }
`;

export const SNoNavigationText = styled.span`
  font-size: 14;
  line-height: 22px;
  font-weight: 300;
`;

export const SScrollableSections = styled(PerfectScrollbar)`
  max-height: calc(var(--app-height) - 206px);
  overflow-y: auto;
`;

export const SSections = styled.ul`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 10px;
  }
`;

export const SNavigationLink = styled.a<{ $active: boolean }>`
  position: relative;
  padding-left: 14px;
  font-weight: ${({ $active }) => ($active ? '400' : '300')};
  font-size: 14px;
  display: inline-block;
  line-height: 22px;
  transition: all 0.05s linear;
  color: ${({ $active }) => ($active ? '#41a6e8' : COLORS.darkDoctrix)};

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    opacity: ${({ $active }) => ($active ? '1' : '0')};
    transition: opacity 0.05s linear;
    width: 3px;
    height: 100%;
    background-color: #41a6e8;
  }
`;
