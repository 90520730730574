import {
  FC,
  memo,
  useMemo,
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import isEqual from "lodash/isEqual";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { SContainer, SSpinnerContainer } from "./styled-components";
import { Footer } from "@/components";
import axios from "axios";
import { business } from "@/types";
import { ContentScreen, MainScreen, ExportScreen } from "@/screens";
import { isMobile } from "react-device-detect";
import { DOMEvent } from "@/pages/types";
import { SCROLL_BUTTON_OFFSET } from "@/constants";
import { useDebounceCallback } from "@/hooks";
import { BreadcrumbsContext } from "@/contexts";

const RouterPage: FC = () => {
  const spinnerContainerRef = useRef<HTMLDivElement>(null);

  const location = useLocation();

  const navigate = useNavigate();

  const ctxBreadcrumbs = useContext(BreadcrumbsContext.StateContext);

  const { updateBreadcrumbsData, updateHistory } = useContext(
    BreadcrumbsContext.DispatchContext
  );

  const navigationCaches = useRef(new Map());

  const [isToTopButtonVisible, setIsToTopButtonVisible] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [modulesList, setModulesList] = useState<business.IModuleDto[]>([]);
  const [selectedModule, setSelectedModule] =
    useState<business.IModuleDto | null>(null);
  const [moduleVersion, setModuleVersion] =
    useState<business.IModuleChildrenDto | null>(null);
  const [moduleNavigation, setModuleNavigation] = useState<
    business.INavigationElementDto[]
  >([]);
  const [markdownsLoaded, setMarkdownsLoaded] = useState(true);

  const setApplicationRootHeightSize = useCallback(() => {
    const doc = document.documentElement;

    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    doc.style.setProperty("--scrollbar-width", "15px");
    doc.style.setProperty(
      "--container-width",
      isMobile
        ? `${
            spinnerContainerRef.current?.getBoundingClientRect()
              ? spinnerContainerRef.current?.getBoundingClientRect().width
              : window.innerWidth
          }px`
        : "1360px"
    );
    doc.style.setProperty(
      "--app-width",
      isMobile
        ? `${
            spinnerContainerRef.current?.getBoundingClientRect()
              ? spinnerContainerRef.current?.getBoundingClientRect().width
              : window.innerWidth
          }px`
        : "100%"
    );
  }, []);

  const handleScroll = useCallback(
    (e: DOMEvent<HTMLDivElement>) => {
      if (isMobile) {
        setIsToTopButtonVisible(
          e.target.scrollTop > SCROLL_BUTTON_OFFSET - 300
        );
      }
    },
    [setIsToTopButtonVisible]
  );

  const onScrollDebounced = useDebounceCallback(handleScroll, 100);

  const loadMarkdowns = useCallback(
    async (
      mdNavigation: business.INavigationElementDto[],
      moduleVersion: business.IModuleChildrenDto
    ) => {
      try {
        const mapResultData = (
          innerNavigation: business.INavigationElementDto[],
          result: any[]
        ): any[] => {
          try {
            return innerNavigation.map((nav, index) => {
              if (nav.children)
                return {
                  ...nav,
                  children: mapResultData(
                    nav.children,
                    result[index].status === "fulfilled"
                      ? (result[index] as PromiseFulfilledResult<any>).value
                      : null
                  ),
                };
              return {
                ...nav,
                mdData:
                  result[index].status === "fulfilled"
                    ? (result[index] as PromiseFulfilledResult<any>).value
                    : null,
              };
            });
          } catch (e) {
            console.log(e);
          }
          return [];
        };

        const loadInnerMarkdowns = async (
          innerNavigation: business.INavigationElementDto[]
        ): Promise<any> => {
          try {
            return Promise.allSettled(
              innerNavigation.map((nav) => {
                if (nav.children) return loadInnerMarkdowns(nav.children);

                return nav.mdPath
                  ? axios.get(nav.mdPath)
                  : Promise.resolve(null);
              })
            );
          } catch (e) {
            console.log(e);
          }
          return null;
        };

        const markdownPromises = mdNavigation.map(async (nav) => {
          if (nav.children) return loadInnerMarkdowns(nav.children);
          return nav.mdPath ? axios.get(nav.mdPath) : null;
        });

        Promise.allSettled(markdownPromises).then((result) => {
          const resultData = mdNavigation.map((nav, index) => {
            if (nav.children)
              return {
                ...nav,
                children: result?.[index]
                  ? mapResultData(
                      nav.children,
                      result[index].status === "fulfilled"
                        ? (result[index] as PromiseFulfilledResult<any>).value
                        : null
                    )
                  : [],
              };
            return {
              ...nav,
              mdData:
                result[index].status === "fulfilled"
                  ? (result[index] as PromiseFulfilledResult<any>).value
                  : null,
            };
          });

          navigationCaches.current.set(
            moduleVersion.navigationPath,
            resultData
          );

          setModuleNavigation(resultData);
          setIsUpdating(false);
          setMarkdownsLoaded(true);
        });
      } catch (e) {
        console.log(e);
      }
    },
    []
  );

  const getNavigationData = useCallback(
    async (moduleVersion: business.IModuleChildrenDto) => {
      try {
        if (moduleVersion && moduleVersion.navigationPath) {
          setMarkdownsLoaded(false);

          setIsUpdating(true);

          const { data: newNavigation } = await axios.get(
            moduleVersion.navigationPath
          );

          await loadMarkdowns(newNavigation, moduleVersion);
        } else {
          setMarkdownsLoaded(true);
        }
      } catch (e) {
        console.log(e);
      }
      return [];
    },
    [loadMarkdowns]
  );

  const updateVersionNavigationData = useCallback(async () => {
    if (!moduleVersion) return;

    try {
      const cachedModuleVersionNavigation = navigationCaches.current.get(
        moduleVersion.navigationPath
      );

      if (cachedModuleVersionNavigation) {
        setModuleNavigation(cachedModuleVersionNavigation);

        return;
      }

      await getNavigationData(moduleVersion);
    } catch (e) {
      console.log(e);
    }
  }, [getNavigationData, moduleVersion]);

  const updateNavigationData = useCallback(async () => {
    try {
      const newModule = location.pathname
        ? modulesList.find((md) => location.pathname.startsWith(md.basePath))
        : null;

      if (newModule) {
        const newModuleVersion =
          (newModule.children || []).find(
            (mdVersion: business.IModuleChildrenDto) =>
              location.pathname.startsWith(mdVersion.basePath)
          ) || (newModule.children || [])[0];

        if (!isEqual(newModule, selectedModule)) {
          setSelectedModule(newModule);
        }

        if (!isEqual(newModuleVersion, moduleVersion)) {
          setModuleVersion(newModuleVersion);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [location.pathname, moduleVersion, modulesList, selectedModule]);

  const initApplication = useCallback(async () => {
    try {
      const { data: newModulesList } = await axios.get("/docs/modules.json");

      setModulesList(newModulesList || []);

      if (location.pathname !== "/") {
        const selectedModule = newModulesList.find((md: business.IModuleDto) =>
          location.pathname.startsWith(md.basePath)
        );

        if (selectedModule) {
          const moduleVersion = (selectedModule.children || []).find(
            (mdVersion: business.IModuleChildrenDto) =>
              location.pathname.startsWith(mdVersion.basePath)
          );

          if (!moduleVersion) {
            navigate(
              (selectedModule.children || [])[0].basePath ||
                selectedModule.defaultPath ||
                selectedModule.basePath
            );
          } else {
            const navigation = await getNavigationData(moduleVersion);

            setSelectedModule(selectedModule);
            setModuleNavigation(navigation);
          }
        }

        setInitialized(true);
      } else {
        setInitialized(true);
      }
    } catch (e) {
      console.log(e);
    }
  }, [getNavigationData, location.pathname, navigate]);

  const pageData = useMemo(() => {
    const findInnerNavigation = (
      innerNavigation: business.INavigationElementDto[]
    ): business.INavigationElementDto | null => {
      if (innerNavigation.length === 0) return null;

      const searchResult = innerNavigation.find((nav) =>
        nav.path === location.pathname ? nav : null
      );

      if (searchResult) {
        return searchResult;
      }

      for (let i = 0; i < innerNavigation.length; i++) {
        const nav = innerNavigation[i];
        const searchResult = nav.children
          ? findInnerNavigation(nav.children)
          : null;

        if (searchResult) {
          return searchResult;
        }
      }

      return null;
    };

    return findInnerNavigation(moduleNavigation) || null;
  }, [location, moduleNavigation]);

  useEffect(() => {
    if (initialized) updateVersionNavigationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateVersionNavigationData]);

  useEffect(() => {
    updateBreadcrumbsData({
      navigation: moduleNavigation,
      modules: modulesList,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modulesList, moduleNavigation]);

  useEffect(() => {
    if (initialized) {
      updateHistory(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialized,
    ctxBreadcrumbs.navigation,
    ctxBreadcrumbs.modules,
    location.pathname,
  ]);

  useEffect(() => {
    initApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("resize", setApplicationRootHeightSize);

    setApplicationRootHeightSize();

    return () => {
      window.removeEventListener("resize", setApplicationRootHeightSize);
    };
  }, [setApplicationRootHeightSize]);

  useEffect(() => {
    updateNavigationData();
  }, [updateNavigationData]);

  if (!initialized) {
    return (
      <SSpinnerContainer ref={spinnerContainerRef}>
        <Spinner size={SpinnerSize.large} />
      </SSpinnerContainer>
    );
  }

  return (
    <SContainer onScroll={onScrollDebounced}>
      <Routes>
        <Route
          path="/export"
          element={
            <ExportScreen
              modulesList={modulesList}
              moduleVersion={moduleVersion}
              isToTopButtonVisible={isToTopButtonVisible}
              setIsToTopButtonVisible={setIsToTopButtonVisible}
              moduleNavigation={moduleNavigation}
              isUpdating={isUpdating}
              selectedModule={selectedModule}
              markdownsLoaded={markdownsLoaded}
              pageData={pageData}
            />
          }
        />
        <Route path="/" element={<MainScreen modulesList={modulesList} />} />
        <Route
          path="*"
          element={
            <ContentScreen
              modulesList={modulesList}
              moduleVersion={moduleVersion}
              isToTopButtonVisible={isToTopButtonVisible}
              setIsToTopButtonVisible={setIsToTopButtonVisible}
              moduleNavigation={moduleNavigation}
              isUpdating={isUpdating}
              selectedModule={selectedModule}
              markdownsLoaded={markdownsLoaded}
              pageData={pageData}
            />
          }
        />
      </Routes>
      <Footer />
    </SContainer>
  );
};

export const Router = memo(RouterPage);
