import { COLORS, BREAKPOINTS } from "@/constants";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const SContainer = styled.div<{ $isBurgerMenuOpen?: boolean }>`
  padding: 20px 36px;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  height: var(--app-height);
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${COLORS.borderLight};
  grid-area: nav;

  & > * + * {
    margin-top: 30px;
  }

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    grid-area: nav;
    position: initial;
    padding: 1rem 1rem 0 1rem;
    border-right: none;
    height: fit-content;
    width: 100vw;
  }
`;

export const SModulesList = styled.ul<{ $asBurger?: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, calc(100% / 3 - 1.5rem / 3));
  gap: 0.75rem;

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    ${({ $asBurger }) => ($asBurger ? "display: none" : "")}
  }
`;

export const SNavigation = styled.ul<{ $asBurger?: boolean }>`
  display: ${({ $asBurger }) => ($asBurger ? "flex !important" : "flex")};
  flex-direction: column;
  width: calc(100% - var(--scrollbar-width));
  padding: 1rem 0;

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    padding: 0;
  }

  & > * + * {
    margin-top: 10px;
  }
`;

export const SNavigationSection = styled.ul<{ $level: number }>`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 10px;
  }
`;

export const SScrollableMainContent = styled(PerfectScrollbar)<{
  $modulesContainerHeight: number;
}>`
  display: flex;
  flex-direction: column;
  max-height: calc(
    var(--app-height) -
      ${({ $modulesContainerHeight }) =>
        $modulesContainerHeight + 30 + 40 + 40}px
  );
  overflow-y: auto;
`;

export const SNavLink = styled(NavLink)<{
  $level: number;
  $mainRoot?: boolean;
  $active: boolean;
  $hasIcon: boolean;
}>`
  display: flex;
  align-items: center;
  padding: ${({ $level, $hasIcon }) => {
    let leftPadding = $level === 0 ? ($hasIcon ? 0 : 24) : $level * 24 + 24;

    // if ($level !== 0) {
    //   leftPadding = leftPadding - 24;
    // }

    return `10px 0 0 ${leftPadding}px`;
  }};
  font-weight: 400;
  font-size: ${({ $mainRoot }) => {
    if ($mainRoot) return "20px";

    return "16px";
  }};
  line-height: 1.4;
  position: relative;
  color: ${({ $active }) => ($active ? COLORS.accent : COLORS.darkDoctrix)};

  & > * + * {
    margin-left: 10px;
  }

  & > i {
    font-size: 14px;
    line-height: 1;
  }

  &:hover {
    color: ${COLORS.accentHover};
  }
`;

export const SSearchContainer = styled.div<{ $asBurger?: boolean }>`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 1rem;
  }

  & > div {
    width: calc(100% - var(--scrollbar-width));

    @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
      width: 100%;
    }
  }

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    margin-bottom: 1rem;
    ${({ $asBurger }) => ($asBurger ? "display: none" : "")}
  }
`;

export const SSearchResultsContainer = styled.ul`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 0.5rem;
  }
`;

export const SSearchInnerContainer = styled.ul`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 0.75rem;
  }
`;

export const SSearchNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  font-size: 0.875rem;

  & > div {
    border: 1px solid ${COLORS.grey1};
    padding: 4px 8px;
    border-radius: 4px;
  }
`;

export const SSectionTitle = styled.span`
  font-size: 1rem;
  font-weight: 600;
`;

export const SLogotype = styled.img`
  height: 40px;
  width: auto;
  margin-right: auto;
  grid-area: logotype;
`;
