import { MarkDownView } from "@/components";
import React, { FC, memo, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";

interface IProps {
  markdowns: { data: string }[];
}

const GroupedMarkdownsImpl: FC<IProps> = (props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const reactToPrintFn = useReactToPrint({
    contentRef: containerRef,
  });

  useEffect(() => {
    reactToPrintFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.markdowns]);

  return (
    <div
      style={{ padding: "0 40px" }}
      ref={(instance) => {
        containerRef.current = instance!;
      }}
    >
      {props.markdowns.map((mdData) => (
        <MarkDownView
          markdownsLoaded={true}
          markdownData={mdData.data}
          isToTopButtonVisible={false}
          setIsToTopButtonVisible={() => {}}
        />
      ))}
    </div>
  );
};

export const GroupedMarkdowns = memo(GroupedMarkdownsImpl);
